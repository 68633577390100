// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-designers-js": () => import("./../../../src/pages/designers.js" /* webpackChunkName: "component---src-pages-designers-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-designer-js": () => import("./../../../src/templates/designer.js" /* webpackChunkName: "component---src-templates-designer-js" */),
  "component---src-templates-garment-js": () => import("./../../../src/templates/garment.js" /* webpackChunkName: "component---src-templates-garment-js" */)
}

